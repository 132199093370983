export const PROGRESS_BAR_START = 'PROGRESS_BAR_START';
export const PROGRESS_BAR_STOP  = 'PROGRESS_BAR_STOP';

export const MAIN_MENU_SHOW = 'MAIN_MENU_SHOW';
export const MAIN_MENU_HIDE = 'MAIN_MENU_HIDE';
export const MAIN_MENU_ACTIVE_PROFILE = 'MAIN_MENU_ACTIVE_PROFILE';
export const MAIN_MENU_ACTIVE_MY_ADMISSION = 'MAIN_MENU_ACTIVE_MY_ADMISSION';

export const USER_MENU_SHOW = 'USER_MENU_SHOW';
export const USER_MENU_HIDE = 'USER_MENU_HIDE';
export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';

export const SET_USER_PROFILE = 'SET_USER_PROFILE';

export const AUTH0_SET_TOKEN = 'AUTH0_SET_TOKEN';
export const MY_ADMISSION_DONE = 'MY_ADMISSION_DONE';

export const PROFILE_LOADING_DONE = 'PROFILE_LOADING_DONE';
export const PROFILE_SAVE_DONE = 'PROFILE_SAVE_DONE';

export const SET_CURRENT_FORM = 'SET_CURRENT_FORM';
export const RESET_CURRENT_FORM = 'RESET_CURRENT_FORM';
export const EDIT_FORM_SAVE_DONE = 'EDIT_FORM_SAVE_DONE';

export const ADD_NEW_ADMISSION_START = 'ADD_NEW_ADMISSION_START';
export const ADD_NEW_ADMISSION_CANCEL = 'ADD_NEW_ADMISSION_CANCEL';
export const ADD_NEW_ADMISSION_SET_HOSPITAL = 'ADD_NEW_ADMISSION_SET_HOSPITAL';
export const ADD_NEW_ADMISSION_SET_READMISSION_UUID = 'ADD_NEW_ADMISSION_SET_READMISSION_UUID';
export const ADD_NEW_ADMISSION_SET_ADMISSION_TYPE = 'ADD_NEW_ADMISSION_SET_ADMISSION_TYPE';
export const ADD_NEW_ADMISSION_STEP2 = 'ADD_NEW_ADMISSION_STEP2';
export const ADD_NEW_ADMISSION_STEP_READMISSION_SELECTION = 'ADD_NEW_ADMISSION_STEP_READMISSION_SELECTION';
export const ADD_NEW_ADMISSION_STEP_READMISSION_SELECTION_DONE = 'ADD_NEW_ADMISSION_STEP_READMISSION_SELECTION_DONE';
export const ADD_NEW_ADMISSION_SET_ME_ELSE = 'ADD_NEW_ADMISSION_SET_ME_ELSE';
export const ADD_NEW_ADMISSION_ADD = 'ADD_NEW_ADMISSION_ADD';

export const WIZARD_INIT_REVIEW = 'WIZARD_INIT_REVIEW';
export const WIZARD_INIT_ADD = 'WIZARD_INIT_ADD';
export const WIZARD_INIT_EDIT = 'WIZARD_INIT_EDIT';
export const WIZARD_CHANGE_STEP = 'WIZARD_CHANGE_STEP';
export const WIZARD_SET_UUID = 'WIZARD_SET_UUID';
export const WIZARD_ALL_REQUIRED_FIELDS_FILLED = 'WIZARD_ALL_REQUIRED_FIELDS_FILLED';
export const WIZARD_REQUIRED_FIELDS_NOT_FILLED = 'WIZARD_REQUIRED_FIELDS_NOT_FILLED';
export const WIZARD_SAVE = 'WIZARD_SAVE';
export const WIZARD_AUTO_SAVE = 'WIZARD_AUTO_SAVE';
export const WIZARD_SIG_PAD_DRAW = 'WIZARD_SIG_PAD_DRAW';
export const WIZARD_SIG_PAD_RESET = 'WIZARD_SIG_PAD_RESET';
export const WIZARD_REMOVE_MEDICATION = 'WIZARD_REMOVE_MEDICATION';
export const WIZARD_SET_MEDICATIONS_COUNT = 'WIZARD_SET_MEDICATIONS_COUNT';
export const WIZARD_DEC_MEDICATIONS_COUNT = 'WIZARD_DEC_MEDICATIONS_COUNT';
export const WIZARD_INC_MEDICATIONS_COUNT = 'WIZARD_INC_MEDICATIONS_COUNT';
export const WIZARD_RESET = 'WIZARD_RESET';
export const WIZARD_ADD_ERROR = 'WIZARD_ADD_ERROR';
export const WIZARD_REMOVE_ERROR = 'WIZARD_REMOVE_ERROR';

export const COMPLETED_INIT = 'COMPLETED_INIT';

export const CUSTOM_LIST_ADD = 'CUSTOM_LIST_ADD';