import * as actionTypes from "../store/actions/actionTypes";
import axios from "axios";
import {useSelector} from "react-redux";
import React from "react";

export const loadExistingToken = (url, dispatch) => {
    let loggedIn = false;
    let existingAdmissions = JSON.parse(localStorage.getItem("admissions")) || [];
    console.log('[Auth.loadExistingToken()] existingAdmissions', existingAdmissions);

    existingAdmissions = existingAdmissions.filter((token) => token.expiration > Date.now());
    console.log('[Auth.loadExistingToken()] filtered', Date.now(), existingAdmissions);

    existingAdmissions.forEach((item) => {
        if ((item.url === url) && (item.expiration > Date.now())) {
            loggedIn = item;
        }
    });

    if (loggedIn) {
        dispatch({type: actionTypes.SET_ADMISSION, admission: loggedIn.admission});
    }
    console.log('[Auth.loadExistingToken()] loggedIn', loggedIn);
    return loggedIn;
}

export const handleLogin = (url, dateOfBirth, surname, dispatch, successCallback, errorCallback) => {
    axios.post(`/api/patient/auth/admission`, {url:url.replace('/',''), dateOfBirth, surname})
        .then((response) => {
            console.log('[LoginPage] login response', response)
            if (response.data.admission) {
                dispatch({type: actionTypes.SET_ADMISSION, admission: response.data.admission, hospitalFeatures: response.data.hospitalFeatures});

                let existingAdmissions = JSON.parse(localStorage.getItem("admissions")) || [];

                existingAdmissions = existingAdmissions.filter((token) => token.expiration > Date.now());

                existingAdmissions.push({url, admission:response.data.admission, expiration: Date.now()+86400000})
                localStorage.setItem("admissions", JSON.stringify(existingAdmissions));
                successCallback();
            } else {
                errorCallback();
            }
        })
        .catch(function (error) {
            // handle error
            console.error('[LoginPage]', error);
            errorCallback();
        })
    ;
}

export const useAuth = () => {
    const userAdmission = useSelector(state => state.main.userAdmission);
    const impersonateToken = useSelector(state => state.impersonate.impersonateToken);

    const getAuthorizationToken = () => {
        const token = impersonateToken ? `i/${impersonateToken}` :  userAdmission.token;
        console.log('[useAuth] getAuthorizationToken()', token);
        return token;
    }
    const getAxiosAuthConfig = () => {
        return {headers: {'Authorization': getAuthorizationToken()}}
    }
    const getPatientPortalUrlPrefix = () => {
        return impersonateToken ? `i/${impersonateToken}` :  userAdmission.hash;
    }
    const getAuthorizationTokenInput = () => {
        return <input type="hidden" name="authorization" value={getAuthorizationToken()}/>;
    }

    return {getAuthorizationHeader: getAuthorizationToken, getAxiosAuthConfig, getPatientPortalUrlPrefix, getAuthorizationTokenInput}
}

export const useAuthThunk = (getState) => {
    const userAdmission = getState().main.userAdmission;
    const impersonateToken = getState().impersonate.impersonateToken;

    const getAuthorizationToken = () => {
        const token = impersonateToken ? `i/${impersonateToken}` :  userAdmission.token;
        console.log('[useAuth] getAuthorizationToken()', token);
        return token;
    }
    const getAxiosAuthConfig = () => {
        return {headers: {'Authorization': getAuthorizationToken()}}
    }
    const getPatientPortalUrlPrefix = () => {
        return impersonateToken ? `i/${impersonateToken}` :  userAdmission.hash;
    }

    return {getAuthorizationHeader: getAuthorizationToken, getAxiosAuthConfig, getPatientPortalUrlPrefix}
}