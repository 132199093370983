import React, {useEffect} from 'react';
import {Route, Routes, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import TasksPage from "./views/TasksPage";
import DocumentPage from "./views/DocumentPage";
import StripePaymentCompletedPage from "./views/StripePaymentCompletedPage";
import AdmissionMessagePage from "./views/AdmissionMessagePage";
import ReviewPage from "./views/ReviewPage";
import Loading from "../components/Loading";
import Error from "../components/Error";

import * as impersonateActions from '../store/actions/impersonate';
import ImpersonateMessage from "../components/ImpersonateMessage";

const ImpersonatePatientPortal = () => {
    const {loading, error} = useSelector(state => state.impersonate);
    const {impersonateToken} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(impersonateActions.init(impersonateToken));
    }, []);

    if (loading) {
        return <Loading />
    }
    if (error) {
        return (
            <Error>
                Impersonation error - {error}<br/>
                Impersonation Token - {impersonateToken}
            </Error>
        );
    }

    // Most routes should be duplicated in PatientPortal
    return (
        <>
            <ImpersonateMessage />
            <Routes>
                <Route element={<StripePaymentCompletedPage/>} path={`/payment/success/:apiHash`} />
                <Route element={<DocumentPage/>}         path={`/document/:yamlTemplateVersion/:documentHash/:documentPage`} />
                <Route element={<ReviewPage/>}           path={`/review/:documentHash`} />
                <Route element={<AdmissionMessagePage/>} path={`/message/:hash`} />
                <Route element={<TasksPage/>}            path={`/*`} />
            </Routes>
        </>
    );
}

export default ImpersonatePatientPortal;