import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from "../../utils/auth";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from '../../store/actions/actionTypes';

const SendEmailPopup = ({ onClose }) => {
    const { getAxiosAuthConfig } = useAuth();
    const [isSending, setIsSending] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [error, setError] = useState('');
    const [emailValid, setEmailValid] = useState(false);

    const dispatch = useDispatch();
    const emailData = useSelector(state => state.main.userAdmission.email);
    const [email, setEmail] = useState(emailData);

    const validateEmail = (email) => {
        const isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
        setEmailValid(isValid);
    };

    useEffect(() => {
        validateEmail(emailData); 
    }, [emailData]);

    const handleSendEmail = async () => {
        setIsSending(true);
        dispatch({ type: actionTypes.LOADING_START });
        try {
            const response = await axios.post(
                '/api/patient/admission/send-email',
                { email },
                getAxiosAuthConfig()
            );

            if (response.data.success) {
                setEmailSent(true);
                setError('');
            } else {
                setError('Failed to send email. Please try again.');
            }
        } catch (error) {
            setError('Temporary System Unavailability. Please try again shortly.');
        } finally {
            setIsSending(false);
        }
    };

    const handleSubmitEmail = () => {
        handleSendEmail();
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        validateEmail(newEmail); 
    };

    return (
        <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            padding: '20px'
        }}>
            <div style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                maxWidth: '90%',
                width: '100%',
                maxWidth: '400px',
                textAlign: 'center',
                position: 'relative',
                boxSizing: 'border-box'
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '20px'
                }}>
                    <h3 style={{ margin: 0, fontSize: '1.25rem' }}>Send Email</h3>
                    <button 
                        onClick={onClose}  
                        style={{
                            border: 'none',
                            background: 'none',
                            cursor: 'pointer',
                            padding: '0',
                            zIndex: 1010
                        }}
                        aria-label="Close"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ width: '24px', height: '24px', color: '#888' }}>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div style={{ borderTop: '1px solid #ddd', width: '100%', margin: '20px 0' }}></div>
                {emailSent ? (
                    <p style={{ margin: 0 }}>Email sent successfully!</p>
                ) : (
                    <>
                        <div style={{ textAlign: 'left', marginBottom: '10px' }}>
                            <p style={{ margin: 0 }}>
                                We understand that sometimes it's easier to complete forms on a desktop computer. If that's the case, enter your email below and we'll send a link to your patient portal so you can easily open it up in any web browser on your PC.
                            </p>
                            <br />
                            <p style={{ margin: 0 }}>Enter your email address below:</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <label htmlFor="emailInput" style={{ marginRight: '10px', marginTop: '8px' }}>Email</label>
                            <input 
                                id="emailInput"
                                type="email" 
                                className="form-control" 
                                placeholder="Enter your email address"
                                value={email} 
                                onChange={handleEmailChange} 
                                required
                                style={{ flex: '1', maxWidth: 'calc(100% - 110px)', boxSizing: 'border-box' }} 
                            />
                        </div>
                        {error && (
                            <p style={{ color: 'red', margin: '10px 0' , textAlign: 'left'}}>{error}</p>
                        )}
                    </>
                )}
                <div style={{ borderTop: '1px solid #ddd', width: '100%', margin: '20px 0' }}></div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {!emailSent && (
                        <button 
                            onClick={handleSubmitEmail} 
                            className="btn btn-primary btn-lg"
                            disabled={!emailValid || isSending} 
                            style={{ flex: '1', maxWidth: '150px' }}
                        >
                            {isSending ? (
                                <span>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    {' '} Sending...
                                </span>
                            ) : (
                                'Submit'
                            )}
                        </button>
                    )}
                    <span 
                        onClick={onClose} 
                        style={{ color: '#000000', cursor: 'pointer', fontSize: '14px', marginLeft: '10px' }}
                    >
                        {emailSent ? 'Close' : 'Cancel'}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default SendEmailPopup;
