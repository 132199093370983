import * as wizardActionTypes from '../actionTypes';
import * as actionTypes from '../../../../store/actions/actionTypes';
import axios from 'axios';
import { push } from '@lagunovsky/redux-react-router';
import {destroy, isDirty} from 'redux-form';

import {isAllRequiredFieldsFilled} from '../../utils/parseRequiredFields';
import {
    reviewBackendValidation_wasFailed,
    reviewBackendValidation_dispatchCustomErrors,
    reviewBackendValidation_validateErrors
} from "../../utils/reviewBackendValidation";
import {useAuthThunk} from "../../../../utils/auth";

export const wizardInitEdit = (documentPage, initialValues) => {
    return (dispatch, getState) => {
        console.log('[Document_v2 / Actions] wizardInitEdit')

        const document = getState().main.currentDocument;

        dispatch({type: actionTypes.REVIEW_RESET});

        dispatch({
            type: wizardActionTypes.WIZARD_INIT_EDIT,
            admissionType: document.template,
            vars: initialValues,
            uuid: document.apiHash,
            currentStep: documentPage,
            files: [], //response.data.files,
            hospital: [], //response.data.hospital
        });

        dispatch({
            type: actionTypes.PROGRESS_PANEL_INIT,
            steps: document.template.steps.map(item => item.title),
            currentStep: parseInt(documentPage),
            allStepsEnabled: isAllRequiredFieldsFilled(document.template, initialValues) && !reviewBackendValidation_wasFailed(document.systemData, initialValues),
            documentHash: document.hash
        })

        // redux-form is not initialized yet, so we do not have formWizard.values
        reviewBackendValidation_dispatchCustomErrors(dispatch,
            reviewBackendValidation_validateErrors(document, document.documentData)
        );
    };
};

export const wizardSave = (redirectTo = false) => {
    return (dispatch, getState) => {
        const state = getState();
        const document = getState().main.currentDocument;
        const {getAxiosAuthConfig, getPatientPortalUrlPrefix} = useAuthThunk(getState);
        if (!state.form || !state.form.wizard || (!state.form.wizard.anyTouched && !isDirty('wizard')(state))) {
            console.log('[Document_v2 / wizardSave] Skipping save - form is not dirty');
            if (redirectTo) {
                dispatch(push(redirectTo));
            }
        } else {
            dispatch({type: actionTypes.LOADING_START});
            // dispatch({type: actionTypes.PROGRESS_BAR_START});
            dispatch({type: actionTypes.DOCUMENT_PROCESSING_START});
            console.log('[Document_v2 / wizardSave] Saving wizard, redirectTo=',redirectTo);
            const values = {...state.form.wizard.values};
            values._lastStep = state.wizard.currentStep;

            if (!state.progressPanel.allStepsEnabled) {
                if (isAllRequiredFieldsFilled(state.wizard.admissionType, values)) {
                    dispatch({type: actionTypes.PROGRESS_PANEL_ALL_STEPS_ENABLED, enable: true});
                }
            } else {
                if (!isAllRequiredFieldsFilled(state.wizard.admissionType, values)) {
                    dispatch({type: actionTypes.PROGRESS_PANEL_ALL_STEPS_ENABLED, enable: false});
                }
            }
            dispatch({type: wizardActionTypes.WIZARD_SAVE, value: values});
            dispatch({type: actionTypes.UPDATE_CURRENT_DOCUMENT_DATA, data: values});

            axios.post(`/api/patient/admission/document/${document.hash}/v2/save`, values, getAxiosAuthConfig())
                .then((response) => {
                    dispatch({type: actionTypes.DOCUMENT_PROCESSING_DONE});
                    dispatch({type: actionTypes.LOADING_DONE});
                    if (redirectTo) {
                        dispatch(push(redirectTo));
                    }
                });

        }
    }
};

export const wizardAutoSave = (redirectTo = false) => {
    return (dispatch, getState) => {
        const state = getState();
        const document = state.main.currentDocument;
        const { getAxiosAuthConfig } = useAuthThunk(getState);

        if (!state.form || !state.form.wizard || (!state.form.wizard.anyTouched && !isDirty('wizard')(state))) {
            console.log('[Document_v2 / wizardSave] Skipping save - form is not dirty');
            if (redirectTo) {
                dispatch(push(redirectTo));
            }
            return;
        }

        console.log('[Document_v2 / wizardSave] Saving wizard, redirectTo=', redirectTo);
        const values = { ...state.form.wizard.values };
        values._lastStep = state.wizard.currentStep;

        axios.post(`/api/patient/admission/document/${document.hash}/v2/save`, values, getAxiosAuthConfig())
            .then((response) => {
                if (redirectTo) {
                    dispatch(push(redirectTo));
                }
            })
            .catch(error => {
                console.error("Save failed", error);
                dispatch({ type: actionTypes.LOADING_DONE });
            });
    }
}

export const wizardChangeStep = (newStep) => {
    return (dispatch, getState) => {
        dispatch({
            type: wizardActionTypes.WIZARD_CHANGE_STEP,
            value: parseInt(newStep)
        });
        dispatch({
            type: actionTypes.PROGRESS_PANEL_CHANGE_STEP,
            currentStep: parseInt(newStep)
        });
    }
};