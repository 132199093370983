import React, {useEffect, useState} from 'react';
import axios from 'axios'
import {useSelector, useDispatch} from "react-redux";
import LogRocket from 'logrocket';

import Loading from "../../components/Loading";
import * as actionTypes from '../../store/actions/actionTypes';
import * as wizardActionTypes from '../../components/Document_v2/store/actionTypes';
import {Link} from "react-router-dom";
import Theme_SupportPhone from "../../components/Theme/Theme_SupportPhone";
import Tasks_ProcessingBlock from "../../components/Tasks/Tasks_ProcessingBlock";
import Tasks_CutoffBlock from "../../components/Tasks/Tasks_CutoffBlock";
import Theme_Logo from "../../components/Theme/Theme_Logo";
import Theme_Footer from "../../components/Theme/Theme_Footer";
import {useAuth} from "../../utils/auth";
import Tasks_StripePayment_Receipt from "../../components/Tasks/Tasks_StripePayment_Receipt";
import SendEmailPopup from './SendEmailPage'
const TasksPage = () => {
    const [loading, setLoading] = useState(true);
    const userAdmissionTasks = useSelector(state => state.main.userAdmissionTasks);
    const {hospitalFeatures} = useSelector(state => state.main);
    
    const {getAxiosAuthConfig, getPatientPortalUrlPrefix} = useAuth();
    const dispatch = useDispatch();
    const [showPopup, setShowPopup] = useState(false);

    const handleClick = (e) => {
        e.preventDefault(); 
        setShowPopup(true);  
    };

    const handleClosePopup = () => {
        setShowPopup(false); 
    };
    useEffect(() => {
        LogRocket.identify(getPatientPortalUrlPrefix());
        dispatch({type: wizardActionTypes.WIZARD_RESET});
    }, []);

    const fetchData = async () =>  {

        axios.get(`/api/patient/admission/tasks`, getAxiosAuthConfig())
            .then((response) => {
                if (response.data.tasks) {
                    console.log('[TasksPage] Got tasks', response.data.tasks);
                    dispatch({type: actionTypes.SET_TASKS, tasks:response.data.tasks});
                    setLoading(false);
                }
            })
    };

    useEffect(() => {
        fetchData()
    }, []);

    // TODO: refactor to separate components, add test coverage
    const documentLink = task => <li key={task.hash}>
        <Link to={`/${getPatientPortalUrlPrefix()}/${task.type}/${task.yamlTemplate_version}/${task.hash}/1`} className="tasks-item">
            {task.title}
            <span className="icon icon-arrow-right"/>
        </Link>
    </li>;

    const renderPendingTask = task => {
        if (task.type === 'document') {
            return documentLink(task);
        } else if (task.type === 'message') {
            return <li key={task.hash}>
                <Link to={`/${getPatientPortalUrlPrefix()}/message/${task.hash}`} className="tasks-item">
                    {task.title}
                    <span className="icon icon-arrow-right"/>
                </Link>
            </li>
        } else if (task.type === 'stripe-payment') {
            return <li key={task.hash}>
                <a href={`${process.env.REACT_APP_PAY_PATH}/stripe/payment/${task.hash}`} className="tasks-item">
                    {task.title}
                    <span className="icon icon-arrow-right"/>
                </a>
            </li>
        } else if (task.type === 'payment') {
            return <li key={task.hash}>
                <a href={`${process.env.REACT_APP_PAY_PATH}/payment/${task.hash}?u=${getPatientPortalUrlPrefix()}`} className="tasks-item">
                    {task.title}
                    <span className="icon icon-arrow-right"/>
                </a>
            </li>
        }
    };

    const renderCompletedTask = task => {
        if (task.title === 'Excess Payment') {
            return <Tasks_StripePayment_Receipt task={task} />;
        }
        if (task.type === 'message') {
            return <li key={task.hash}>
                <Link to={`/${getPatientPortalUrlPrefix()}/${task.type}/${task.hash}`} className="tasks-item">
                    {task.title}
                    <span className="icon icon-arrow-right"/>
                </Link>
            </li>
        }
        if ((task.type === 'document') && task._forceLink) {
            return documentLink(task);
        }
        return (
            <li key={task.hash}>
                <a href='#' className="tasks-item cursor-default tasks-completed-item">
                    {task.title}
                    <span className="icon icon-arrow-right"/>
                </a>
            </li>
        )
        ;
    }
    const renderNextTask = task => {
        if (task.type === 'message') {
            return <li key={task.hash}>
                <Link to={`/${getPatientPortalUrlPrefix()}/${task.type}/${task.hash}`} className="tasks-item">
                    {task.title}
                    <span className="icon icon-arrow-right"/>
                </Link>
            </li>
        }
        return (
            <li key={task.hash}>
                <a href='#' className="tasks-item cursor-default">
                    {task.title}
                    <span className="icon icon-arrow-right"/>
                </a>
            </li>
        )
            ;
    }

    const renderTasks = () => {
        return <main id="main" className="pt-4 pt-md-5 pb-0 pb-md-6">
            <div className="container-simple">
                <section className="tasks-block">
                    <div className="head-block head-simple">
                        <h2>Pending Tasks</h2>
                        <span className={`counter active ${userAdmissionTasks.pending.length > 0 ? 'red' : ''}`}>{userAdmissionTasks.pending.length}</span>
                    </div>

                    {userAdmissionTasks.pending.length ?
                        <ul className="tasks list-unstyled">
                            {userAdmissionTasks.pending.map(renderPendingTask)}
                        </ul> :
                        <span className="tasks-text">🎆 You’re all done! We will send you an SMS if anything else needs your attention.</span>
                    }
                </section>

                {(userAdmissionTasks.next.length > 0) &&
                    <section className="tasks-block">
                        <div className="head-block head-simple">
                            <h2>Next Tasks</h2>
                            <span className="counter">{userAdmissionTasks.next.length}</span>
                        </div>

                        <ul className="tasks list-unstyled">
                            {userAdmissionTasks.next.map(renderNextTask)}
                        </ul>
                    </section>
                }
                <Tasks_ProcessingBlock tasks={userAdmissionTasks.processing} />

                <section className="tasks-block">
                    <div className="head-block head-simple">
                        <h2>Completed Tasks</h2>
                        <span className="counter blue">{userAdmissionTasks.completed.length}</span>
                    </div>

                    <ul className="tasks list-unstyled">
                        {userAdmissionTasks.completed.length ?
                                userAdmissionTasks.completed.map(renderCompletedTask)
                            :
                            <span className="tasks-text">You have no completed tasks</span>
                        }
                        {hospitalFeatures && hospitalFeatures.send_email && (
                        <li>
                            <a href="#" className="tasks-item" onClick={handleClick}>
                                Send platform link to my email
                                <span className="icon icon-arrow-right" />
                            </a>
                        </li>
                    )}

                        {showPopup && <SendEmailPopup onClose={handleClosePopup} />}
                    </ul> 
                </section>
                <Tasks_CutoffBlock tasks={userAdmissionTasks.cutoff} />
            </div>
        </main>
    }

    return (
        <div id="wrapper">
            <header className="header sticky-header">
                <div className="container-fluid">
                    <Theme_Logo />
                    <Theme_SupportPhone />
                </div>
            </header>

            {loading ? <Loading /> : renderTasks()}

            <Theme_Footer />
        </div>
    );
}

export default TasksPage;