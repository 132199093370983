import React from 'react';
import { Route, Routes, useLocation, useParams, matchRoutes } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import TasksPage from "./views/TasksPage";
import WelcomePage from "./views/WelcomePage";
import {loadExistingToken} from "../utils/auth";
import DocumentPage from "./views/DocumentPage";
import StripePaymentCompletedPage from "./views/StripePaymentCompletedPage";
import AdmissionMessagePage from "./views/AdmissionMessagePage";
import ReviewPage from "./views/ReviewPage";
import SendEmailPage from './views/SendEmailPage'

const PatientPortal = () => {
    const url = useLocation().pathname;
    let { hash } = useParams();
    const fixedUrl = hash;
    const dispatch = useDispatch();
    let loggedIn = useSelector(state => state.main.userLoggedIn) || loadExistingToken(fixedUrl, dispatch);

    console.log('[PatientPortal]', url, loggedIn);

    // Most routes should be duplicated in ImpersonatePatientPortal
    if (loggedIn) {
        return <Routes>
                    <Route element={<StripePaymentCompletedPage/>}  exact path={`/payment/success/:apiHash`} />
                    <Route element={<DocumentPage/>}                exact path={`/document/:yamlTemplateVersion/:documentHash/:documentPage`} />
                    <Route element={<ReviewPage/>}                  exact path={`/review/:documentHash`} />
                    <Route element={<AdmissionMessagePage/>}        exact path={`/message/:hash`} />
                    <Route element={<SendEmailPage />}              exact path={`/request-desktop-version`} />
                    <Route element={<TasksPage/>}                   exact path={`*`} />
                </Routes>
    } else {
        return <Routes>
            <Route element={<WelcomePage/>}            exact path={`*`} />
        </Routes>
    }
}

export default PatientPortal;
