import cloneDeep from 'lodash/cloneDeep';

import * as actionTypes from "../actionTypes";
import PubSub from "pubsub-js";
import * as pubSubMessages from "../pubSubMessages";

const initialState = {
    uuid: null,
    hospitalId: null,
    typeId: null,
    ready: false,
    admissionType: {},
    steps: [],
    currentForm: {},
    currentStep: 0,
    initialValues: {},
    hospital: '',
    medicationsCount: 1,
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.WIZARD_RESET:
            return initialState;
        case actionTypes.WIZARD_INIT_EDIT:
            if (!action.admissionType.steps[action.currentStep-1]) {
                console.error('[BUG] probably, currentStep = ACA-93-X, debug by reference');
            }
            return {
                ...initialState,
                ready: true,
                admissionType: cloneDeep(action.admissionType),
                steps: action.admissionType.steps.map((step) => step.title),
                currentStep: parseInt(action.currentStep),
                currentForm: cloneDeep(action.admissionType.steps[action.currentStep-1].form),
                initialValues: {...action.vars},
                uuid: action.uuid,
                hospital: action.hospital
            };
        case actionTypes.WIZARD_CHANGE_STEP:
            return {
                ...state,
                ready: true,
                currentStep: parseInt(action.value),
                currentForm: cloneDeep(state.admissionType.steps[action.value-1].form),
            };
        case actionTypes.WIZARD_SAVE:
            return {
                ...state,
                initialValues: action.value,
            };
        case actionTypes.WIZARD_AUTO_SAVE:
            return {
                ...state,
                initialValues: action.value,
            };
        case actionTypes.WIZARD_SET_MEDICATIONS_COUNT:
            return {
                ...state,
                medicationsCount: action.value,
            };
        case actionTypes.WIZARD_DEC_MEDICATIONS_COUNT:
            return {
                ...state,
                medicationsCount: state.medicationsCount-1,
            };
        case actionTypes.WIZARD_INC_MEDICATIONS_COUNT:
            return {
                ...state,
                medicationsCount: state.medicationsCount+1,
            };
    }
    return state;
};

export default reducer;